@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(40% - 0px);
}

.lastNameRoot {
  width: calc(60% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}
.userTypeContainer {
  margin-top: 24px;
  width: 100%;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
.userTypeContainer > div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (--viewportMedium) {
    gap: 20px;
  }
}
